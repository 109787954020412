<template>
  <div class="frequency-projects">
    <h1 class="main-title">Сборы частотности попроектно</h1>
    <br/>
		<div class="menu-line">
			<v-select-project
				v-model="filters.project_id"
				class="narrow menu-line__item"
				chips
				small-chips
				deletable-chips
				multiple
			/>
			<div class="menu-line__item">
				<v-menu
					v-model="menu"
					:close-on-content-click="false"
					:nudge-right="40"
					transition="scale-transition"
					offset-y
					min-width="auto"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							class="narrow"
							v-model="filters.date"
							label="Выберите дату"
							prepend-icon="mdi-calendar"
							readonly
							outlined
							hide-details
							dense
							v-bind="attrs"
							v-on="on"
						></v-text-field>
					</template>
					<v-date-picker
						v-model="filters.date"
						locale="ru"
						first-day-of-week="1"
						no-title
						:max="new Date().toISOString().substr(0, 10)"
						@input="menu = false"
					></v-date-picker>
				</v-menu>
			</div>
		</div>

    <br/>
    <p><strong>Всего запросов:</strong> {{calcSum('count_ch_all')}} </p>
		<p><strong>Всего запросов с WS (CH): </strong> {{calcSum('count_ch_finish')}}</p>
		<p><strong>Всего проектов: </strong> {{tabItems.length}}</p>
    <br/>
    <br/>
		<v-tabs v-model="tabId" grow v-if="tabs.length">
			<v-tab v-for="item in tabs" :key="item.id">
				<span>{{item.name}}</span>
				<span v-if="item.name.includes('mobile')">
					<v-icon small
					>
						mdi-cellphone
					</v-icon>
				</span>
			</v-tab>
		</v-tabs>
		<br/>
		<v-data-table
			class="save-table"
			:headers="headers"
			:loading="loading"
      :items="tabItems"
      :items-per-page="-1"
			hide-default-footer
			dense
		>
    <template v-slot:item.prc_ch_finish="{ item }">
      <strong v-if="item.prc_ch_finish !== null">{{item.prc_ch_finish.toLocaleString({maximumFractionDigits: 2})}}%</strong>
      <span v-else>-</span>
    </template>
		</v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import selectProject from '@/components/selectProject'
export default {
	components: {
		'v-select-project': selectProject
	},
	data () {
		return {
			menu: false,
			tabId: 0,
			tabs: [
				{
					id: 0,
					name: 'Yandex',
					title: 'yandex'
				},
				{
					id: 1,
					name: 'Yandex Mobile',
					title: 'yandex-mobile'
				}
			],
			headers: [
				{
					text: 'Наименование проекта',
					value: 'name',
					align: 'left',
					sortable: false
				},
				{
					text: 'ID проекта',
					value: 'project_id',
					align: 'center',
					sortable: false
				},
				{
					text: 'Всего запросов',
					value: 'count_ch_all',
					align: 'center',
					sortable: true
				},
				{
					text: 'Запросы с WS (CH)',
					value: 'count_ch_finish',
					align: 'center',
					sortable: true
				},
				{
					text: '% запросов с WS (CH)',
					value: 'prc_ch_finish',
					align: 'center',
					sortable: true
				}
			],
			loading: false,
			filters: {
				date: null,
				project_id: []
			},
			selectedProjects: [],
			loadingProjects: false,
			search: null
		}
	},

	computed: {
		...mapState({
			wsData: state => state.save.wsSavedData
		}),
		urlFilters () {
			return { ...this.$route.query }
		},
		currentTab () {
			return this.tabs.find((el) => el.id === this.tabId).title
		},
		tabItems () {
			return this.wsData && this.wsData[this.currentTab] ? this.wsData[this.currentTab] : []
		}
	},

	methods: {
		...mapActions({
			getWsData: 'save/getWsData'
		}),

		async setFilters () {
			if (this.urlFilters.date) {
				this.filters.date = new Date(this.urlFilters.date).toISOString().substr(0, 10)
			} else {
				this.filters.date = new Date().toISOString().substr(0, 10)
			}

			if (this.urlFilters.project_id) {
				this.filters.project_id = Array.isArray(this.urlFilters.project_id) ? this.urlFilters.project_id.map((el) => +el) : [+this.urlFilters.project_id]
			}
		},

		/* Обновление URL */
		async updateUrl (query) {
			await this.$router.replace({
				query: {
					...this.$route.query,
					...query
				}
			}).catch((_) => {})
		},

		calcSum (parameter) {
			return this.tabItems.length ? this.tabItems.reduce(function (acc, obj) {
				return acc + obj[parameter]
			}, 0) : 0
		}
	},

	async mounted () {
		try {
			await this.setFilters()
		} catch (error) {
			this.$notify({ type: 'error', title: error })
		} finally {
			this.loading = false
		}
	},

	watch: {
		filters: {
			deep: true,
			async handler (val) {
				if (val.date) {
					await this.updateUrl({
						date: val.date,
						project_id: val.project_id.length ? val.project_id : undefined
					})

					try {
						this.loading = true
						this.$store.commit('save/clearWsData')
						await this.getWsData({
							date: val.date,
							project_ids: val.project_id.length ? val.project_id : undefined,
							raw: true
						})
					} catch (err) {
						console.log(err)
						this.loading = false
					} finally {
						this.loading = false
					}
				}
			}
		}
	}
}
</script>

<style lang="scss">
.menu-line__item .v-chip {
	font-size: 10px;
	line-height: 1;

	&:not(.v-chip--active) {
		background: transparent;
	}
}
.narrow {
	max-width: 300px;
	font-size: 12px;
}

.menu-line {
	display: flex;
	align-items: center;

	&__item {
		&:not(:last-child) {
			margin-right: 10px;
		}
	}
}
</style>
